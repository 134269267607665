import { NgModule, APP_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'
import { provideHttpClient, withInterceptorsFromDi, withFetch} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { SaasComponent } from './saas/saas.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import { SolarComponent } from './solar/solar.component';

@NgModule({ 
    declarations: [
        AppComponent,
        HomeComponent,
        ContactComponent,
        SaasComponent
        // SolarComponent
    ],
    bootstrap: [AppComponent],
    // imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }),
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule],
    providers: [
        { provide: APP_ID, useValue: 'serverApp' },
        provideHttpClient(withInterceptorsFromDi(), withFetch())
    ]
 })

export class AppModule { }
