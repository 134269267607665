<div class="container d-flex flex-column vh-100" *ngIf='state' [@slideDown]>
<div class="contact-clean">
    <form (ngSubmit)="submitForm(contactForm.value)" #contactForm="ngForm">
        <h2 class="text-center">Get in touch</h2>
        <div class="form-group"><input
          class="form-control"
          type="text"
          name="name"
          placeholder="Name"
          [(ngModel)]="name"
          #nameInput="ngModel"
          required>

          <div class="help is-error" *ngIf="nameInput.invalid && nameInput.touched">
            Your name is required.
          </div>
        </div>
        <div class="form-group"><input class="form-control" type="email" name="email" [(ngModel)]="email" placeholder="Email"
          #emailInput="ngModel"
          required
          email>

          <div class="help is-error" *ngIf="emailInput.invalid && emailInput.touched">
            Your email is required and needs to be an email.
          </div>
        </div>
        <div class="form-group"><textarea class="form-control" name="message" [(ngModel)]="message" placeholder="Message" rows="14"></textarea></div>
        <div class="form-group"><button class="btn btn-primary" type="submit"
          [disabled]="contactForm.invalid">
          send </button></div>
    </form>
</div>
</div>
