<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2/dist/css/bootstrap.min.css" rel="stylesheet">

<div class="container">
  <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
    <a href='' class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
      <img src="assets/logo.webp" alt="VibeZ Logo">
    </a>
    <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
      <li><a [routerLink]="['']" routerLinkActive="active" class="nav-link px-2"
         [routerLinkActiveOptions]={exact:true}>Home</a></li>
      <li><a [routerLink]="['solution']" routerLinkActive="active" class="nav-link px-2"
         [routerLinkActiveOptions]={exact:true}>Solution</a></li>
      <li><a [routerLink]="['contact']" routerLinkActive="active" class="nav-link px-2 "
         [routerLinkActiveOptions]={exact:true}>Contact</a></li>
    </ul>
    <div class="col-md-3 text-end">
      <a  href="https://app.vibez.energy" class="btn btn-outline-primary me-2"
               role="button" aria-pressed="true">Login</a>
    </div>
  </header>
</div>

<router-outlet></router-outlet>
<!-- <div [@routeAnimations]="o && o.activatedRouteData 
      && o.activatedRouteData['animation']">
    <router-outlet #o="outlet"></router-outlet>
</div> -->


<footer class="bg-light mt-auto">
  <!-- Copyright -->
  <!-- <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);"> -->
  <div class="text-center p-3" >
    © 2023 VibeZ AI Ltd.
  </div>
  <!-- Copyright -->
</footer>
