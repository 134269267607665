<div class="container" >
  <div class="text-secondary px-4 py-5 text-center"
      [ngStyle]="{backgroundImage:'url(assets/Network.webp)'}">
    <div class="py-5" *ngIf='state' [@zoomFadeIn]>
      <h1 class="display-5 fw-bold text-white"><i>VibeZ</i></h1>
      <!-- <h1 class="display-5 fw-bold text-white">VibeZ AI</h1> -->
      <div class="col-lg-6 mx-auto">
        <p class="fs-5 mb-4 text-white"><span>Data-Driven Energy Management & Trading</span></p>
      </div>
    </div>
  </div>
</div>

<div class="container px-4 py-5" id="featured-3">
    <div class="row justify-content-around">
    <div class="feature col">
      <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
        <object data="assets/NN.svg" width="200em" height="100em"> </object>
      </div>
      <h1 class="fs-2">AI Engine</h1>
      <p>Electric assets you own provide you with data. <br> Leverage it!<br> Let the machine continuously learn your portfolio behaviour & its energy profile.</p>
    </div>
    <div class="feature col">
      <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
        <object data="assets/renewable.svg" width="200em" height="100em"></object>
      </div>
      <h1 class="fs-2">Renewable Energy</h1>
      <p>Clean energy is tagged & available to everyone.<br> No infrastructure on premises.<br>Green PPA's with developers.</p>
     
    </div>
    <div class="feature col">
      <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
        <object data="assets/saas.svg" width="200em" height="100em"> </object>
      </div>
      <h1 class="fs-2">Manage & Trade</h1>
      <p>Take data-driven decisions.<br>Operate your tasks automatically.<br>Maximize profits & mitigate financial risks.</p>
    </div>
  </div>
</div>
    

