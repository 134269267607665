import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { SEOService } from '../seoservice.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Title, Meta} from "@angular/platform-browser";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({ transform: 'translateY(-20%)' }),
                animate('1000ms ease-in-out', style({ transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                animate('1000ms ease-in-out', style({ transform: 'translateY(-20%)' }))
            ])
        ])
    ],
    standalone: false
})
export class ContactComponent implements OnInit {
  name:'';
  email: '';
  message:'';

  state = 'shown';

  ngAfterViewInit() {
    setTimeout( () => {
      this.state = 'hidden';
    }, 200);
  }  
  constructor(
    private http: HttpClient, 
    private seoService: SEOService,
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
    this.title.setTitle("VibeZ - Get in Touch"); 
    this.meta.updateTag({ 
      name: 'description',
      content: 'Get in touch with VibeZ for inquiries, support, or feedback. We are here to assist you with all your renewable energy and AI-driven electricity management needs.'});
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  submitForm(data){
    this.http.post('https://hm18665jk7.execute-api.eu-central-1.amazonaws.com/noiam/contactMe/contact-us', data)
    .subscribe((result) => {
      console.warn("result", result)
    })
    console.warn(data);

    const message = 'Thanks, we will get back to you!';
    alert(message);

  }
}


// import { Component, OnInit } from '@angular/core';
// import { HttpClient } from "@angular/common/http";

// @Component({
//   selector: 'app-contact',
//   templateUrl: './contact.component.html',
//   styleUrls: ['./contact.component.css']
// })
// export class ContactComponent implements OnInit {
//   name:'';
//   email: '';
//   message:'';

//   constructor(private http: HttpClient) { }

//   ngOnInit(): void {
//   }

//   submitForm(data){
//     this.http.post('https://hm18665jk7.execute-api.eu-central-1.amazonaws.com/noiam/contactMe/contact-us', data)
//     .subscribe((result) => {
//       console.warn("result", result)
//     })
//     console.warn(data);

//     const message = 'Thanks, we will get back to you!';
//     alert(message);

//   }
// }

