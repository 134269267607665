import { Component } from '@angular/core';
import {RouterModule} from '@angular/router';
import { Title, Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-solar',
  imports: [[RouterModule]],
  templateUrl: './solar.component.html',
  styleUrl: './solar.component.css'
})
export class SolarComponent {
  constructor(
    private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("VibeZ Products");
    this.meta.updateTag({ 
      name: 'description',
      content: "Participate in day-ahead power markets or manage flexible PPA's to maximize the value of your renewable assets with VibeZ's innovative energy AI solutions."});
  }
}

