import { Component, OnInit } from '@angular/core';
import { SEOService } from '../seoservice.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Title, Meta} from "@angular/platform-browser";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    animations: [
        trigger('zoomFadeIn', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.5)' }),
                animate('1000ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
            ])
        ])
    ],
    standalone: false
})
export class HomeComponent implements OnInit {
  constructor(
    private seoService: SEOService,
    private title: Title,
    private meta: Meta) { } 

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
    this.title.setTitle("VibeZ - Data Driven Energy Management & AI Trading"); 
    this.meta.updateTag({ 
      name: 'description',
      content: 'VibeZ is a robotic supplier of renewable energy, developing AI to improve electricity management and trading, specializing in data-driven decisions for the energy sector through continuous data mining of electric assets'});
  }

  state = 'shown';

  ngAfterViewInit() {
    setTimeout( () => {
      this.state = 'hidden';
    }, 200);
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
}
