import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { SEOService } from '../seoservice.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import AOS from 'aos';
import { isPlatformBrowser} from '@angular/common';
import { Title, Meta} from "@angular/platform-browser";


@Component({
    selector: 'app-saas',
    templateUrl: './saas.component.html',
    styleUrls: ['./saas.component.css'],
    animations: [
        trigger('slideLeft', [
            transition(':enter', [
                style({ transform: 'translateX(-10%)' }),
                animate('3000ms ease-in-out', style({ transform: 'translateX(0)' }))
            ]),
            transition(':leave', [
                animate('3000ms ease-in-out', style({ transform: 'translateX(-10%)' }))
            ])
        ]),
        trigger('slideRight', [
            transition(':enter', [
                style({ transform: 'translateX(10%)' }),
                animate('3000ms ease-in-out', style({ transform: 'translateX(0)' }))
            ]),
            transition(':leave', [
                animate('3000ms ease-in-out', style({ transform: 'translateX(10%)' }))
            ])
        ]),
        trigger('bounce', [
            transition(':enter', [
                animate('1s ease-in', keyframes([
                    style({ transform: 'translateY(0)', offset: 0 }),
                    style({ transform: 'translateY(-30px)', offset: 0.3 }),
                    style({ transform: 'translateY(0)', offset: 0.5 }),
                    style({ transform: 'translateY(-15px)', offset: 0.7 }),
                    style({ transform: 'translateY(0)', offset: 1.0 })
                ]))
            ])
        ])
    ],
    standalone: false
})

export class SaasComponent implements OnInit {

  constructor(private seoService: SEOService,
     @Inject(PLATFORM_ID) private platformId: Object,
     private title: Title,
     private meta: Meta) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
    // AOS.init();
    if (isPlatformBrowser(this.platformId)) { AOS.init({ once: true, duration: 500 }); }
    
    this.title.setTitle("VibeZ AI Solution"); 
    this.meta.updateTag({ 
      name: 'description',
      content: 'VibeZ specializes in data-driven decisions for the energy sector through continuous data mining of electric assets, enhancing electricity management and trading with innovative AI solutions'});
  }

  state = 'shown';

  ngAfterViewInit() {
    setTimeout( () => {
      this.state = 'hidden';
    }, 200);
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
  
}
