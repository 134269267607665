<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">


<div class="container px-4 py-5 text-center" id="featured-3">
  <div class="row justify-content-around">
      <div class="feature col">
          <div class="d-flex align-items-end mb-3">
              <img src="assets/renewable.svg" class="feature-icon-small" alt="renewable icon">
              <h1 class="fs-4 ms-3">VibeZ Standard</h1>
          </div>
          <p class="feature-text">VibeZ Forecast Engine.<br>Mitigate the risk of production uncertainty.</p>
          <div class="button-container">
            <button [routerLink]="['/contact']" type="button" class="btn btn-primary">Get an Offering</button>
        </div>
          <ul class="list-group list-group-flush">
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Day-ahead Forecast of Power Supply</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Integrate with Internal/External Stakeholders</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Automate Workflow</li>
          </ul>
      </div>
      <div class="feature col">
          <div class="d-flex align-items-end mb-3">
              <img src="assets/NN.svg" class="feature-icon-small" alt="NN icon">
              <h1 class="fs-4 ms-3">VibeZ Pro</h1>
          </div>
          <p class="feature-text">Participate in the day-ahead power markets or<br>manage flexible PPA's to maximize the value of your supply.</p>
          <div class="button-container">
              <button [routerLink]="['/contact']" type="button" class="btn btn-primary">Get an Offering</button>
          </div>
          <ul class="list-group list-group-flush">
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Day-ahead Forecast of Power Supply</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Integrate with Internal/External Stakeholders</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Automate Workflow</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Simulate Pricing Alternatives</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Data-driven Trading</li>
          </ul>
      </div>
      <div class="feature col">
          <div class="d-flex align-items-end mb-3">
              <img src="assets/saas.svg" class="feature-icon-small" alt="NN icon">
              <h1 class="fs-4 ms-3">VibeZ Offtaker</h1>
          </div>
          <p class="feature-text">Accurately aggregate and match your demand and supply towards a robotic supply.</p>
          <div class="button-container">
            <button [routerLink]="['/contact']" type="button" class="btn btn-primary">Get an Offering</button>
        </div>
          <ul class="list-group list-group-flush">
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Day-ahead Forecast Demand Portfolio</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Day-ahead Forecast Supply Portfolio</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Play Matching Game</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Clustering Profiles</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Automate Workflow</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Simulate Pricing Alternatives</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Data-driven Trading</li>
              <li class="list-group-item"><i class="fa-solid fa-bolt"></i>Data-driven, Tailored Customer Profile Package Offering</li>
          </ul>
      </div>
  </div>
</div>
