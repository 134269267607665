import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
// import { slideInAnimation } from './route-animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // animations: [ slideInAnimation ]
})
export class AppComponent {
  // title = 'VibeZ, Data-Driven Energy Management';
  title = 'VibeZ - Data Driven Energy Management & AI Trading';


  constructor(
    private titleService: Title,
    private metaService: Meta
    ){}

  ngOnInit() {
  this.titleService.setTitle(this.title);
  this.metaService.addTags([
    {name: 'keywords', content: 'Energy, Electricity, VibeZ AI, VibeZ energy, machine learning, AI, renewable enrgy, artificial intelligence, SaaS'},
    // {name: 'description', content: 'VibeZ AI is a SaaS, specializing in data-driven energy management & trading. Let the machine continuously learn your electric assets so it can manage your portfolio for you & trade the electricity in the best manner to maximize profits and mitigate financial risks.'},
    // {name: 'description', content: 'VibeZ AI: A robotic supplier of renewable energy, specializing in data-driven energy management & trading by continuous data mining of electric assets.'},
    {name: 'description', content: 'VibeZ is a robotic supplier of renewable energy, using AI to improve electricity management and trading, specializing in data-driven decisions for the energy sector through continuous data mining of electric assets'},
    {name: 'robots', content: 'index, follow'}
  ]);
  }

}
