import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent  } from "./home/home.component";
import { ContactComponent } from './contact/contact.component';
import { SaasComponent } from './saas/saas.component';
import { SolarComponent } from './solar/solar.component';

const routes: Routes = [
  // {path: '', pathMatch: 'full', redirectTo: '/home' },
  // {path: 'home', component: HomeComponent, data: {animation: 'Home'}},
  {path: '', component: HomeComponent},
  // {path: '', component: HomeComponent, data: {animation: 'Home'}},
  {path: 'contact', component: ContactComponent},
  // {path: 'solution', component:SaasComponent, data: {animation: 'Article'}}
  {path: 'solution', component:SaasComponent},
  {path: 'products', component:SolarComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledNonBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

