import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { provideHttpClient, withInterceptorsFromDi, withFetch} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { SaasComponent } from './saas/saas.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({ 
    declarations: [
        AppComponent,
        HomeComponent,
        ContactComponent,
        SaasComponent
    ],
    bootstrap: [AppComponent],
    imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule],
    providers: [provideHttpClient(withInterceptorsFromDi(), withFetch())] })

export class AppModule { }
