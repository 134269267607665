import { Component, OnInit } from '@angular/core';
import { SEOService } from '../seoservice.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('zoomFadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.5)' }),
        animate('1000ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  constructor(private seoService: SEOService) { }

  ngOnInit(): void {
    this.createLinkForCanonicalURL();
  }

  state = 'shown';

  ngAfterViewInit() {
    setTimeout( () => {
      this.state = 'hidden';
    }, 200);
  }

  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
}
